export const rotateSetting = [
    { x: 0, z: 0 },
    { x: -0.06300000000000003, z: (-Math.PI / 180) * 60 + (Math.PI / 180) * 62.55 },
    {
        x:-0.014999999999999951,
        z: (-Math.PI / 180) * 60 + (Math.PI / 180) * 150.84,
    },
    { x: -0.07600000000000005, z: (-Math.PI / 180) * 60 + (Math.PI / 180) * 242.19 },
];

export const cubeAmount = 100;
