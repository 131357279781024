// @ts-nocheck

import isMobile from "ismobilejs";
import * as THREE from "three";
import React, { useRef, useEffect, useState, useMemo } from "react";
import { LightProps } from "@react-three/fiber";
import { useSpring, animated } from "@react-spring/three";

import lightSetting from "./lightSetting";
import { Color } from "three";

type Props = {
    nowIndex: number;
    bingo: boolean;
};

const Light: React.FC<LightProps & Props> = ({ nowIndex, bingo }) => {
    const yellowLight = useRef<THREE.PointLight>();
    const pinkLight = useRef<THREE.PointLight>();
    const greenLight = useRef<THREE.PointLight>();
    const blueLight = useRef<THREE.PointLight>();

    const [mouseMovement, setMouseMovement] = useState<number[]>([0, 0]);
    const [isChangedIntensity, setIsChangedIntensity] = useState(false);

    const per = useMemo(() => 0.15, []);

    const yellowLightIntensity = useSpring({
        intensity:
            nowIndex === 0 || nowIndex >= 2
                ? isChangedIntensity
                    ? nowIndex < 1
                        ? 1.5
                        : 1.5
                    : 1
                : 0,
        position: [
            lightSetting[0].position[0] + mouseMovement[0] * per,
            lightSetting[0].position[1] - mouseMovement[1] * per,
            lightSetting[0].position[2],
        ],
    });

    const pinkLightIntensity = useSpring({
        intensity: nowIndex === 1 ? (isChangedIntensity ? 0.8 : 0.4) : 0,
        position: [
            lightSetting[1].position[0] + mouseMovement[0] * per,
            lightSetting[1].position[1] + mouseMovement[1] * per,
            lightSetting[1].position[2],
        ],
    });

    const greenLightIntensity = useSpring({
        intensity: nowIndex >= 2 ? (isChangedIntensity ? 0.5 : 0.25) : 0,
        position: [
            lightSetting[2].position[0] + mouseMovement[0] * per,
            lightSetting[2].position[1] + mouseMovement[1] * per,
            lightSetting[2].position[2],
        ],
    });

    const [blueLightIntensity] = useSpring(() => {
        return {
            intensity: nowIndex <= 1 ? (isChangedIntensity ? 0.6 : 1) : 0,
            distance:
                nowIndex <= 1 ? (window.innerWidth < 768 ? 800 : 2000) : 2000,
            position: [
                lightSetting[3].position[0] + mouseMovement[0] * per,
                lightSetting[3].position[1],
                lightSetting[3].position[2],
            ],
        };
    }, [isChangedIntensity, mouseMovement, nowIndex]);

    const darkLight = useSpring({
        intensity: nowIndex === 0 || (nowIndex === 1 && bingo) ? 0.4 : 0,
    });

    const ambientSprings = useSpring({
        color:
            bingo && nowIndex === 3 ? new Color(0x7e854f) : new Color(0x586068),
    });

    const handleMouseMove = (e: MouseEvent) => {
        if (!isMobile(window.navigator).any)
            setMouseMovement([e.clientX, e.clientY]);
    };

    useEffect(() => {
        window.addEventListener("mousemove", handleMouseMove);

        return () => {
            window.removeEventListener("mousemove", handleMouseMove);
        };
    }, []);

    useEffect(() => {
        let timer: ReturnType<typeof setTimeout>;

        if (bingo) {
            timer = setTimeout(() => {
                setIsChangedIntensity(true);
            }, 1200);
        } else {
            setIsChangedIntensity(false);
        }

        return () => {
            if (timer) clearTimeout(timer);
        };
    }, [bingo]);

    return (
        <>
            <animated.ambientLight
                castShadow
                {...ambientSprings}
                intensity={0.0635}
            />

            <animated.pointLight
                castShadow
                ref={yellowLight}
                color={lightSetting[0].color}
                distance={1300}
                {...yellowLightIntensity}
            />

            <animated.pointLight
                castShadow
                ref={pinkLight}
                color={lightSetting[1].color}
                distance={1300}
                {...pinkLightIntensity}
            />

            <animated.pointLight
                castShadow
                ref={greenLight}
                color={lightSetting[2].color}
                distance={1600}
                {...greenLightIntensity}
            />

            <animated.pointLight
                castShadow
                ref={blueLight}
                color={lightSetting[3].color}
                {...blueLightIntensity}
            />

            <animated.pointLight
                castShadow
                color={new Color(0, 0.24, 0.6)}
                distance={2000}
                position={[
                    -1500.4020004272461, -270.9089965820312, -200.0920104980469,
                ]}
                {...darkLight}
            />
        </>
    );
};

export default Light;
