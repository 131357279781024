import { shaderMaterial } from "@react-three/drei";
import vertextShader from "./vertextShader";
import fragmentShader from "./fragmentShader";
import { extend, Object3DNode } from "@react-three/fiber";
import { Color } from "three";

const MixedMaterial = shaderMaterial(
    {
        uTime: 0,
        uAlpha: 0,
        uColor1: new Color(0x323b4f),
        uColor2: new Color(0x1b3165),
        uColor3: new Color(0x171717),
        uColor4: new Color(0x93477f),
    },
    vertextShader,
    fragmentShader,
    (material) => {
        if (material) {
            material.transparent = true;
        }
    }
);

// Shaders with Typescript and React three fiber:
// https://stackoverflow.com/questions/65459024/shaders-with-typescript-and-react-three-fiber

declare global {
    namespace JSX {
        interface IntrinsicElements {
            mixedMaterial: Object3DNode<
                typeof MixedMaterial,
                typeof MixedMaterial
            >;
        }
    }
}

extend({ MixedMaterial });
