export const mobileSetting = [
    {
        px: -150,
        py: 4000,
        pz: -200,
        rx: -Math.PI / 2,
        ry: 0,
        rz: Math.PI / 2 + 0.4246,
    },
    {
        px: 0,
        py: 3800,
        pz: 0,
        rx: -Math.PI / 2 + 0.03,
        ry: -0.02,
        rz: Math.PI / 2 - 0.5246,
    },
    {
        px: -150,
        py: 5100,
        pz: 100,
        rx: -Math.PI / 2 + 0.05,
        ry: -0.055,
        rz: Math.PI,
    },
    {
        px: -100,
        py: 4500,
        pz: -200,
        rx: -Math.PI / 2,
        ry: 0,
        rz: Math.PI / 2,
    },
];

export const desktopSetting = [
    {
        px: -150,
        py: 3200,
        pz: -200,
        rx: -Math.PI / 2,
        ry: 0,
        rz: Math.PI / 2 + 0.4246,
    },
    {
        px: 50,
        py: 3000,
        pz: -170,
        rx: -Math.PI / 2 + 0.03,
        ry: -0.02,
        rz: Math.PI / 2 - 0.5246,
    },
    {
        px: -10,
        py: 3000,
        pz: 50,
        rx: -Math.PI / 2 + 0.098,
        ry: -0.05,
        rz: Math.PI,
    },
    {
        px: -100,
        py: 3500,
        pz: -200,
        rx: -Math.PI / 2,
        ry: 0,
        rz: Math.PI / 2,
    },
];
