import { Vector3, Camera } from "three";
import React, { useMemo, useRef, useState } from "react";
import { OrbitControls, PerspectiveCamera } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";
import { Easing, Tween, update } from "@tweenjs/tween.js";
import { mobileSetting, desktopSetting } from "./cameraSetting";

type Props = {
    nowIndex: number;
    bingo: {
        status: boolean;
        changeStatus: (boolean: boolean) => void;
    };
    reset: {
        status: boolean;
        changeStatus: (boolean: boolean) => void;
    };
};

type CameraProps = {
    px: number;
    py: number;
    pz: number;
    rx: number;
    ry: number;
    rz: number;
};

// https://jsfiddle.net/prisoner849/hsjgLc4d/
// https://andreasrohner.at/posts/Web%20Development/JavaScript/Simple-orbital-camera-controls-for-THREE-js/

const UserViewCamera: React.FC<Props> = ({ nowIndex, bingo, reset }) => {
    const [isAniFinish, setIsAniFinish] = useState([
        false,
        false,
        false,
        false,
    ]);

    const cameraRef = useRef<Camera>();

    const cameraParam = useMemo(
        () => ({
            makeDefault: true,
            aspect: 1,
            far: 100000000,
            filmGauge: 35,
            filmOffset: 0,
            focus: 50,
            fov: 24.814,
            near: 0.001,
            scale: new Vector3(1, 1, 1),
            up: new Vector3(0, 1, 0),
            zoom: 1,
        }),
        []
    );

    const cameraAnimationSetting = (
        camera: Camera,
        index: number,
        from: CameraProps,
        to: CameraProps
    ) => {
        const coords = { ...from };
        const tween = new Tween(coords);

        return tween
            .to(to, 700)
            .easing(Easing.Circular.InOut)
            .onUpdate(() => {
                camera.position.x = coords.px;
                camera.position.y = coords.py;
                camera.position.z = coords.pz;
                camera.rotation.x = coords.rx;
                camera.rotation.y = coords.ry;
                camera.rotation.z = coords.rz;
            })
            .onComplete(() => {
                if (reset.status) {
                    setIsAniFinish([false, false, false, true]);
                    reset.changeStatus(false);
                } else {
                    const nowStatus = [...isAniFinish];
                    nowStatus[index] = true;
                    if (index !== 3) nowStatus[3] = false;
                    setIsAniFinish(nowStatus);
                }

                camera.position.x = to.px;
                camera.position.y = to.py;
                camera.position.z = to.pz;
                camera.rotation.x = to.rx;
                camera.rotation.y = to.ry;
                camera.rotation.z = to.rz;
            });
    };

    const zoomIn = (camera: Camera, setting: CameraProps) => {
        return camera.position.y <= setting.py * 0.9
            ? (camera.position.y = setting.py * 0.9)
            : (camera.position.y -= (setting.py * 0.1) / 120);
    };

    useFrame(({ camera }) => {
        const setting =
            window.innerWidth < 1024 ? mobileSetting : desktopSetting;
        const moving = -0.03;

        if (nowIndex === 0 && bingo.status) {
            const tween = cameraAnimationSetting(
                camera,
                0,
                { ...setting[3], ry: moving * nowIndex },
                setting[0]
            );

            if (!isAniFinish[0]) {
                tween.start();
            } else {
                tween.stop();
                zoomIn(camera, setting[0]);
            }
        } else if (nowIndex === 1 && bingo.status) {
            const tween = cameraAnimationSetting(
                camera,
                1,
                { ...setting[3], ry: moving * nowIndex },
                setting[1]
            );

            if (!isAniFinish[1]) {
                tween.start();
            } else {
                tween.stop();
                zoomIn(camera, setting[1]);
            }
        } else if (nowIndex === 2 && bingo.status) {
            const tween = cameraAnimationSetting(
                camera,
                2,
                { ...setting[3], ry: moving * nowIndex },
                setting[2]
            );

            if (!isAniFinish[2]) {
                tween.start();
            } else {
                tween.stop();
                zoomIn(camera, setting[2]);
            }
        } else if (!bingo.status) {
            const tween = cameraAnimationSetting(
                camera,
                3,
                { ...setting[nowIndex - 1], py: camera.position.y },
                { ...setting[3], ry: nowIndex === 3 ? 0 : moving * nowIndex }
            );

            if (!isAniFinish[3]) {
                tween.start();
            } else {
                tween.stop();
            }
        }

        update();
    });

    // useHelper(camera, CameraHelper, 100, "cyan");

    return (
        <>
            <PerspectiveCamera
                {...cameraParam}
                position={[-100, 3500, -200]}
                rotation={[-Math.PI / 2, 0, Math.PI / 2]}
                ref={cameraRef}
            />
            {/* <OrbitControls camera={cameraRef.current} /> */}
        </>
    );
};

export default UserViewCamera;
