import { OrthographicCamera, PerspectiveCamera, Texture } from "three";

function getCameraRatio(camera: PerspectiveCamera | OrthographicCamera) {
    switch (camera.type) {
        case "PerspectiveCamera": {
            return camera.aspect;
        }
        case "OrthographicCamera": {
            const width = Math.abs(camera.right - camera.left);
            const height = Math.abs(camera.top - camera.bottom);
            return width / height;
        }
        default: {
            throw new Error(`not supported in ProjectedMaterial`);
        }
    }
}

function computeScaledDimensions(
    texture: Texture,
    camera: PerspectiveCamera | OrthographicCamera,
    textureScale: number,
    cover: boolean
) {
    // return some default values if the image hasn't loaded yet
    if (!texture.image) {
        return [1, 1];
    }

    // return if it's a video and if the video hasn't loaded yet
    if (texture.image.videoWidth === 0 && texture.image.videoHeight === 0) {
        return [1, 1];
    }

    const sourceWidth =
        texture.image.naturalWidth ||
        texture.image.videoWidth ||
        texture.image.clientWidth;
    const sourceHeight =
        texture.image.naturalHeight ||
        texture.image.videoHeight ||
        texture.image.clientHeight;

    const ratio = sourceWidth / sourceHeight;
    const ratioCamera = getCameraRatio(camera);
    const widthCamera = 1;
    const heightCamera = widthCamera * (1 / ratioCamera);
    let widthScaled;
    let heightScaled;
    if (cover ? ratio > ratioCamera : ratio < ratioCamera) {
        const width = heightCamera * ratio;
        widthScaled = 1 / ((width / widthCamera) * textureScale);
        heightScaled = 1 / textureScale;
    } else {
        const height = widthCamera * (1 / ratio);
        heightScaled = 1 / ((height / heightCamera) * textureScale);
        widthScaled = 1 / textureScale;
    }

    return [widthScaled, heightScaled];
}

export default computeScaledDimensions;
