import { useSpring, config, a } from "react-spring";
import { useEffect } from "react";
import isMobile from "ismobilejs";

import url from "utils/path";
import Props from "./types";
import "./style.css";

const Hint = ({ visible, status }: Props) => {
    const [mouseSprings, api] = useSpring(() => {
        const radius =
            window.innerWidth * 0.04 > 40 ? window.innerWidth * 0.04 : 40;

        return {
            x: window.innerWidth / 2 - radius,
            y: window.innerHeight + radius * 2,
            opacity: 0,
            immediate: true,
        };
    }, []);

    useEffect(() => {
        let timer: ReturnType<typeof setTimeout>;

        if (visible) {
            const radius =
                window.innerWidth * 0.04 > 40 ? window.innerWidth * 0.04 : 40;

            const padding = window.innerWidth < 1024 ? 60 : 120;

            timer = setTimeout(() => {
                api.start(() => ({
                    y: window.innerHeight - radius * 2 - padding,
                    opacity: 1,
                }));
            }, 1500);
        }

        return () => {
            clearTimeout(timer);
        };
    }, [api, visible]);

    useEffect(() => {
        const handleMouseMoving = (e: MouseEvent) => {
            const radius =
                window.innerWidth * 0.04 > 40 ? window.innerWidth * 0.04 : 40;

            api.start(() => ({
                x: e.pageX - radius,
                y: e.pageY - radius,
                config: config.stiff,
            }));
        };

        if (!isMobile(window.navigator).any) {
            window.addEventListener("mousemove", handleMouseMoving);
        }
        return () => {
            if (!isMobile(window.navigator).any)
                window.removeEventListener("mousemove", handleMouseMoving);
        };
    }, [api]);

    return (
        <a.div
            className={`mouse-drag ${
                status.dragging && !status.bingo ? "dragging" : ""
            } ${status.bingo ? "bingo" : ""}`}
            style={mouseSprings}
        >
            <div className="drag">
                <img src={`${url}/arrow-top.svg`} alt="arrow" className="top" />
                <span>Drag</span>
                <img
                    src={`${url}/arrow-bottom.svg`}
                    alt="arrow"
                    className="bottom"
                />
            </div>
            <span className="click">
                {status.nowIndex === 2 ? "Restart" : "Next"}
            </span>
        </a.div>
    );
};

export default Hint;
