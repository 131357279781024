import { Color } from "three";

const gradientColors = [
    {
        uColor1: new Color(0x2c2f31),
        uColor2: new Color(0x005e60), // 0C7A0C
        uColor3: new Color(0x171717),
        uColor4: new Color(0x005600), // 0B634A
    },
    {
        uColor1: new Color(0x2c2f31),
        uColor2: new Color(0x1c1242), // 2B0C77
        uColor3: new Color(0x171717),
        uColor4: new Color(0x11564f), // 0E5777
    },

    {
        uColor1: new Color(0x2c2f31),
        uColor2: new Color(0x0f6039), // 117C4B
        uColor3: new Color(0x171717),
        uColor4: new Color(0x7f6313), // 7C790E
    },
];

export default gradientColors;
