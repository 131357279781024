const fragmentShader = `
    #define STANDARD
    #ifdef PHYSICAL
        #define IOR
        #define SPECULAR
    #endif
    uniform vec3 diffuse;
    uniform vec3 emissive;
    uniform float roughness;
    uniform float metalness;
    uniform float opacity;
    #ifdef IOR
        uniform float ior;
    #endif
    #ifdef SPECULAR
        uniform float specularIntensity;
        uniform vec3 specularColor;
        #ifdef USE_SPECULARINTENSITYMAP
            uniform sampler2D specularIntensityMap;
        #endif
        #ifdef USE_SPECULARCOLORMAP
            uniform sampler2D specularColorMap;
        #endif
    #endif
    #ifdef USE_CLEARCOAT
        uniform float clearcoat;
        uniform float clearcoatRoughness;
    #endif
    #ifdef USE_SHEEN
        uniform vec3 sheenColor;
        uniform float sheenRoughness;
        #ifdef USE_SHEENCOLORMAP
            uniform sampler2D sheenColorMap;
        #endif
        #ifdef USE_SHEENROUGHNESSMAP
            uniform sampler2D sheenRoughnessMap;
        #endif
    #endif
    varying vec3 vViewPosition;
    #include <common>
    #include <packing>
    #include <dithering_pars_fragment>
    #include <color_pars_fragment>
    #include <uv_pars_fragment>
    #include <uv2_pars_fragment>
    #include <map_pars_fragment>
    #include <alphamap_pars_fragment>
    #include <alphatest_pars_fragment>
    #include <aomap_pars_fragment>
    #include <lightmap_pars_fragment>
    #include <emissivemap_pars_fragment>
    #include <bsdfs>
    #include <cube_uv_reflection_fragment>
    #include <envmap_common_pars_fragment>
    #include <envmap_physical_pars_fragment>
    #include <fog_pars_fragment>
    #include <lights_pars_begin>
    #include <normal_pars_fragment>
    #include <lights_physical_pars_fragment>
    #include <transmission_pars_fragment>
    #include <shadowmap_pars_fragment>
    #include <shadowmask_pars_fragment>
    #include <bumpmap_pars_fragment>
    #include <normalmap_pars_fragment>
    #include <clearcoat_pars_fragment>
    #include <roughnessmap_pars_fragment>
    #include <metalnessmap_pars_fragment>
    #include <logdepthbuf_pars_fragment>
    #include <clipping_planes_pars_fragment>

    uniform sampler2D textture;
    uniform sampler2D textture2;
    uniform sampler2D textture3;
    uniform vec3 projectPosition;
    uniform vec3 projectPosition2;
    uniform vec3 projectPosition3;
    uniform vec2 widthScaled;
    uniform vec2 heightScaled;
    uniform vec2 scale;
    uniform int nowIndex;
    uniform bool bingo;
    uniform float processValue;
    uniform float alpha;

    varying vec3 vNormal2;
    varying vec4 vWorldPosition2;
    varying vec4 vTexCoords;
    varying vec4 vTexCoords2;
    varying vec4 vTexCoords3;
    varying float distToCamera;

    float mapRange(float value, float min1, float max1, float min2, float max2) {
        return min2 + (value - min1) * (max2 - min2) / (max1 - min1);
    }

    void main() {
        #include <clipping_planes_fragment>
        vec4 diffuseColor = vec4( diffuse, opacity );
        ReflectedLight reflectedLight = ReflectedLight( vec3( 0.0 ), vec3( 0.0 ), vec3( 0.0 ), vec3( 0.0 ) );
        vec3 totalEmissiveRadiance = emissive;
        #include <logdepthbuf_fragment>
        #include <map_fragment>
        #include <color_fragment>
        #include <alphamap_fragment>
        #include <alphatest_fragment>
        #include <roughnessmap_fragment>
        #include <metalnessmap_fragment>
        #include <normal_fragment_begin>
        #include <normal_fragment_maps>
        #include <clearcoat_normal_fragment_begin>
        #include <clearcoat_normal_fragment_maps>
        #include <emissivemap_fragment>
        // accumulation
        #include <lights_physical_fragment>
        #include <lights_fragment_begin>
        #include <lights_fragment_maps>
        #include <lights_fragment_end>
        // modulation
        #include <aomap_fragment>
        vec3 totalDiffuse = reflectedLight.directDiffuse + reflectedLight.indirectDiffuse;
        vec3 totalSpecular = reflectedLight.directSpecular + reflectedLight.indirectSpecular;
        #include <transmission_fragment>
        vec3 outgoingLight = totalDiffuse + totalSpecular + totalEmissiveRadiance;
        #ifdef USE_CLEARCOAT
            float dotNVcc = saturate( dot( geometry.clearcoatNormal, geometry.viewDir ) );
            vec3 Fcc = F_Schlick( material.clearcoatF0, material.clearcoatF90, dotNVcc );
            outgoingLight = outgoingLight * ( 1.0 - material.clearcoat * Fcc ) + clearcoatSpecular * material.clearcoat;
        #endif
        #ifdef OPAQUE
        diffuseColor.a = 1.0;
        #endif
        // https://github.com/mrdoob/three.js/pull/22425
        #ifdef USE_TRANSMISSION
        diffuseColor.a *= transmissionAlpha + 0.1;
        #endif


        float w = max(vTexCoords.w, 0.0);
        vec2 uv = (vTexCoords.xy / w) * 0.5 + 0.5;

        uv.x = mapRange(uv.x, 0.0, 1.0, 0.5 - scale.x / 2.0, 0.5 + scale.x / 2.0);
        uv.y = mapRange(uv.y, 0.0, 1.0, 0.5 - scale.y / 2.0, 0.5 + scale.y / 2.0);

        float w2 = max(vTexCoords2.w, 0.0);
        vec2 uv2 = (vTexCoords2.xy / w2) * 0.5 / 0.5 + 0.5;

        uv2.x = mapRange(uv2.x, 0.0, 1.0, 0.5 - scale.x / 2.0, 0.5 + scale.x / 2.0);
        uv2.y = mapRange(uv2.y, 0.0, 1.0, 0.5 - scale.y / 2.0, 0.5 + scale.y / 2.0);

        float w3 = max(vTexCoords3.w, 0.0);
        vec2 uv3 = (vTexCoords3.xy / w3) * 0.5 + 0.5;

        uv3.x = mapRange(uv3.x, 0.0, 1.0, 0.5 - scale.x / 2.0, 0.5 + scale.x / 2.0);
        uv3.y = mapRange(uv3.y, 0.0, 1.0, 0.5 - scale.y / 2.0, 0.5 + scale.y / 2.0);

        vec4 outColor = texture2D(textture, uv);
        vec4 outColor2 = texture2D(textture2, uv2);
        vec4 outColor3 = texture2D(textture3, uv3);

        // this makes sure we don't render the textture also on the back of the object
        vec3 projectorDirection = normalize(projectPosition - vWorldPosition2.xyz);
        float dotProduct = dot(vNormal2, projectorDirection);

        vec3 projectorDirection2 = normalize(projectPosition2 - vWorldPosition2.xyz);
        float dotProduct2 = dot(vNormal2, projectorDirection2);

        vec3 projectorDirection3 = normalize(projectPosition3 - vWorldPosition2.xyz);
        float dotProduct3 = dot(vNormal2, projectorDirection3);

        float dotTest3 = dot(vNormal2.z, projectPosition.z);

        vec3 textureColor1 = vec3(0.0);
        vec3 textureColor2 = vec3(0.0);
        vec3 textureColor3 = vec3(0.0);


        if (dotProduct < 0.0) {
            textureColor1 = vec3(0.0);
        } else {
            if (distToCamera > 290.0 && (nowIndex == 0 || nowIndex == 1 && bingo && processValue < 1.5)) {
                textureColor1 = vec3(outColor.a - outColor.a * alpha);
            } else {
                textureColor1 = vec3(0.0);
            }
        }

        if (dotProduct2 < 0.0) {
            textureColor2 = vec3(0.0);
        } else if (
            (nowIndex == 1) ||
            (nowIndex == 2 && bingo && processValue > 1.5 && processValue < 2.4)
            ) {
            textureColor2 = vec3(outColor2.a - outColor2.a * alpha);
        }

        if (dotProduct3 < 0.0) {
            textureColor3 = vec3(0.0);
        } else {
            if (distToCamera > 200.0 &&
                (nowIndex >= 2)
            ) {
                textureColor3 = vec3(outColor3.a - outColor3.a * alpha);
            } else {
                textureColor3 = vec3(0.0);
            }
        }

        gl_FragColor = vec4( textureColor1 + textureColor2 + textureColor3 + outgoingLight, 1.0 );

        #include <tonemapping_fragment>
        #include <encodings_fragment>
        #include <fog_fragment>
        #include <premultiplied_alpha_fragment>
        #include <dithering_fragment>
    }
`;

export default fragmentShader;
