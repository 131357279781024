import "./style.css";

type Props = {
    nowIndex: number;
    bingo: boolean;
    visible: boolean;
};

const ProcessingUi = ({ nowIndex, bingo, visible }: Props) => (
    <div className={`processing-ui ${visible ? "visible" : ""}`}>
        <span>2021</span>
        <div className="ui">
            <div
                className={`circle ${
                    (nowIndex === 0 && bingo) || nowIndex >= 1 ? "now" : ""
                }`}
            ></div>
            <div className="line"></div>
            <div
                className={`circle ${
                    (nowIndex === 1 && bingo) || nowIndex >= 2 ? "now" : ""
                }`}
            ></div>
            <div className="line"></div>
            <div
                className={`circle ${nowIndex === 2 && bingo ? "now" : ""}`}
            ></div>
        </div>
        <span>2022</span>
    </div>
);

export default ProcessingUi;
