import url from "utils/path";
import Props from "./types";
import "./style.css";

const Wording = ({ visible, processValue }: Props) => (
    <div className={`words ${visible ? "visible" : ""} `}>
        {processValue < 1.5 && (
            <div className="words-first">
                <img src={`${url}/answer_1.svg`} alt="設計改變台灣" />
            </div>
        )}
        {processValue > 1.5 && processValue < 2.4 && (
            <div className="words-second">
                <img src={`${url}/answer_2.svg`} alt="美好生活，社會永續" />
            </div>
        )}
        {processValue > 2.4 && (
            <div className="words-third">
                <img src={`${url}/answer_3.svg`} alt="新年快樂" />
            </div>
        )}
    </div>
);

export default Wording;
