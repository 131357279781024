import { CSSProperties } from "react";
import { useSpring, a } from "react-spring";

import url from "utils/path";
import Props from "./types";
import "./style.css";

const Header = ({ visible }: Props) => {
    const logoSprings = useSpring({
        opacity: visible ? 1 : 0,
    });

    return (
        <header>
            <a.img
                src={`${url}/logotype.svg`}
                alt="logotype"
                draggable={false}
                style={logoSprings}
            />
            <div className={`year ${visible ? "visible" : ""}`}>
                <div className="year-number year-2021">
                    <span style={{ "--delay": "0s" } as CSSProperties}>N</span>
                    <span style={{ "--delay": "0.1s" } as CSSProperties}>
                        O
                    </span>
                    <span style={{ "--delay": "0.2s" } as CSSProperties}>
                        W
                    </span>
                </div>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 35 35"
                    className="year-arrow"
                >
                    <path
                        style={{ "--delay": "0.4s" } as CSSProperties}
                        d="M2.24,3.4c19.18,1.78,24,12.92,24,28.11h0"
                    />
                    <polyline
                        style={{ "--delay": "0.8s" } as CSSProperties}
                        points="32.76 25.06 26.21 31.6 19.66 25.06"
                    />
                </svg>
                <div className="year-number year-2022">
                    <span style={{ "--delay": "1.1s" } as CSSProperties}>
                        F
                    </span>
                    <span style={{ "--delay": "1.2s" } as CSSProperties}>
                        U
                    </span>
                    <span style={{ "--delay": "1.3s" } as CSSProperties}>
                        T
                    </span>
                    <span style={{ "--delay": "1.4s" } as CSSProperties}>
                        U
                    </span>
                    <span style={{ "--delay": "1.5s" } as CSSProperties}>
                        R
                    </span>
                    <span style={{ "--delay": "1.6s" } as CSSProperties}>
                        E
                    </span>
                </div>
            </div>
        </header>
    );
};

export default Header;
