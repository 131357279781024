import { shaderMaterial } from "@react-three/drei";
import { extend, Object3DNode } from "@react-three/fiber";
import { Matrix4, UniformsLib, Color, Vector2, DoubleSide } from "three";

import { transferObjectsFormat, mergeObjects } from "utils/adjustObjectFormat";

import fragmentShader from "./fragmentShader";
import vertexShader from "./vertexShader";

const uniforms = mergeObjects([
    transferObjectsFormat(UniformsLib.common),
    transferObjectsFormat(UniformsLib.envmap),
    transferObjectsFormat(UniformsLib.aomap),
    transferObjectsFormat(UniformsLib.lightmap),
    transferObjectsFormat(UniformsLib.emissivemap),
    transferObjectsFormat(UniformsLib.bumpmap),
    transferObjectsFormat(UniformsLib.normalmap),
    transferObjectsFormat(UniformsLib.displacementmap),
    transferObjectsFormat(UniformsLib.roughnessmap),
    transferObjectsFormat(UniformsLib.metalnessmap),
    transferObjectsFormat(UniformsLib.fog),
    transferObjectsFormat(UniformsLib.lights),
]);

const ProjectedMaterial = shaderMaterial(
    {
        ...uniforms,
        emissive: new Color(0x000000),
        roughness: 1.0,
        metalness: 0.0,
        envMapIntensity: 1,
        // customUniforms
        textture: null,
        textture2: null,
        textture3: null,
        savedModelMatrix: new Matrix4(),
        widthScaled: null,
        heightScaled: null,
        scale: new Vector2(1, 1),
        nowIndex: 0,
        bingo: false,
        processValue: 0,
        alpha: 0,
        // camera1
        viewMatrixCamera: null,
        projectionMatrixCamera: null,
        modelMatrixCamera: null,
        projectPosition: null,
        // camera2
        viewMatrixCamera2: null,
        projectionMatrixCamera2: null,
        modelMatrixCamera2: null,
        projectPosition2: null,
        // camera3
        viewMatrixCamera3: null,
        projectionMatrixCamera3: null,
        modelMatrixCamera3: null,
        projectPosition3: null,
    },
    vertexShader,
    fragmentShader,
    (material) => {
        if (material) {
            material.extensions.derivatives = true;
            material.lights = true;
        }
    }
);

declare global {
    namespace JSX {
        interface IntrinsicElements {
            projectedMaterial: Object3DNode<
                typeof ProjectedMaterial,
                typeof ProjectedMaterial
            >;
        }
    }
}

extend({ ProjectedMaterial });
