const cameraPosition = [
    {
        position: [-4000.64459228515625, -500.2353515625, 100.63502502441406],
        rotation: [
            -1.5531844998045068, -0.03770553201673744, 2.007899916599891,
        ],
        quaternion: [
            0.7156969308853149, 0.698406994342804, -0.0016823073383420706,
            0.001641664537601173,
        ],
    },

    {
        position: [60.64459228515625, 4000.556640625, 0],
        rotation: [0, 0, 0],
        quaternion: [0, 0, 0, 0],
    },

    {
        position: [5010.59423828125, 134.2353515625, -93.73041534423828],
        rotation: [-0.9046637187610084, 1.4997135211755395, 0.3972895730090059],
        quaternion: [
            -0.7040301561355591, 0.19257941842079163, -0.6640549898147583,
            0.16212844848632812,
        ],
    },

    {
        position: [-0.31906127929688, -3800.79541015625, 100.671201705932617],
        rotation: [
            1.5463442086891737, -0.0001149444071172293, 0.004699756297845656,
        ],
        quaternion: [
            -0.3718131184577942, 0.387616902589798, -0.5839126110076904,
            -0.6087316274642944,
        ],
    },

    {
        position: [6010.59423828125, 134.2353515625, -1203.73041534423828],
        rotation: [-3.9046637187610084, 1.4997135211755395, 0.3972895730090059],
        quaternion: [
            -0.7040301561355591, 0.19257941842079163, -0.6640549898147583,
            0.16212844848632812,
        ],
    },

    {
        position: [-0.31906127929688, -5500.79541015625, 100.671201705932617],
        rotation: [
            1.5463442086891737, -0.0001149444071172293, 0.004699756297845656,
        ],
        quaternion: [
            -0.3718131184577942, 0.387616902589798, -0.5839126110076904,
            -0.6087316274642944,
        ],
    },
];

export default cameraPosition;
