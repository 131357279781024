const initialModelPosition = [
    {
        x: 49.55884170532225,
        y: 53.00730133056639,
        z: 0,
    },
    {
        x: -1066.1482543945311,
        y: 66.08858489990233,
        z: 1092.64492797851557,
    },
    {
        x: -1000,
        y: -54.34074783325194,
        z: 1871.0747070312498,
    },
    {
        x: -1154.65679931640622,
        y: 31.44282150268554,
        z: 1340.5066528320312,
    },
    { x: 145.55938720703122, y: -2282.92239379882807, z: -60.5393714904785 },
    { x: 1296.8175964355468, y: 2077.2197799682617, z: -294.23791503906244 },
    { x: -1113.76911926269528, y: -224.91099548339838, z: 1203.15812683105463 },
    { x: 1192.5170288085937, y: 1296.29641723632807, z: 1011.943119049072264 },
    { x: 25.69383239746093, y: -2132.16973876953122, z: -397.92208862304676 },
    { x: -3508.89743041992176, y: -135.8939056396484, z: -285.2564392089843 },
    { x: 114.93004608154294, y: -3386.54486083984364, z: -404.9298095703124 },
    { x: 254.52915954589838, y: -3361.54233932495116, z: -573.9611206054686 },
    { x: -166.41491699218747, y: -4450.233917236328, z: -174.63957214355466 },
    { x: -2152.86320495605466, y: 133.5464172363281, z: -2555.7339477539061 },
    { x: -3434.014190673828, y: -227.52941894531244, z: -3168.6463623046873 },
    { x: -565.6794433593749, y: -3065.00101470947264, z: 138.96368408203122 },
    { x: 3000, y: -8.19776153564453, z: 0 },
    { x: -1497.12408447265614, y: 3246.81616210937494, z: -170.02838134765622 },
    { x: -314.2844848632812, y: -3525.4776611328124, z: 3128.2114105224609 },
    { x: 3220.42907714843744, y: 3216.91958618164057, z: 3042.40188980102538 },
    { x: 3411.39617919921864, y: 142.24597167968747, z: -4015.205871582031 },
    { x: 226.91079711914057, y: 3163.4112854003906, z: -3829.025451660156 },
    { x: 224.79768371582026, y: 4220.43051147460932, z: -4827.177062988281 },
    { x: -164.6319427490234, y: -3582.5662841796874, z: -3869.5853881835935 },
];

export default initialModelPosition;
