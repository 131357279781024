import { Color } from "three";

const lightSetting = [
    {
        color: new Color(1, 1, 0), // yellow
        position: [
            -136.72500610351562, -47.01900100708008, -410.93499755859375,
        ],
    },
    {
        color: new Color(1, 0, 1), // pink
        position: [100.51400756835938, -653.9719848632812, 110.41600036621094],
    },
    {
        color: new Color(0.27, 0.75, 0), // green
        position: [-151.51400756835938, -945.5490112304688, 650.9619750976562],
    },
    {
        color: new Color(0, 0.24, 0.75), //blue
        position: [20.4020004272461, 670.9089965820312, -306.0920104980469],
    },
];

export default lightSetting;
