const nodeNames = [
    "Cloth_Surface",
    "Cone",
    "Cube",
    "Cube1",
    "Cube2",
    "Cube3",
    "Cube4",
    "Cube5",
    "Cube6",
    "Cube7",
    "Cube8",
    "Cube9",
    "Cube10",
    "Cube11",
    "Cube12",
    "Cube13",
    "Cube_1",
    "Cylinder1",
    "Cylinder2",
    "Cylinder3",
    "Hat1",
    "Hat2",
    "Hat3",
    "book",
];

export default nodeNames;
