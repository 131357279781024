import { useRef, useEffect } from "react";
import { Euler, ShaderMaterial, Vector3 } from "three";
import { useFrame } from "@react-three/fiber";

import "./MixedMaterial";
import gradientColors from "./gradientColors";

type Props = {
    isTransparent: boolean;
    nowIndex: number;
};

type ColorVariable = "uColor1" | "uColor2" | "uColor3" | "uColor4";

// TS: Property 'uniforms' does not exist on type 'Material':
// https://github.com/mrdoob/three.js/issues/19421

const Gradient = ({ isTransparent, nowIndex }: Props) => {
    const material = useRef<ShaderMaterial>();

    useFrame(({ clock }) => {
        if (material.current) {
            const {
                uniforms: { uTime, uAlpha },
            } = material.current;

            uTime.value = clock.elapsedTime;

            if (isTransparent) {
                if (uAlpha.value > 0) uAlpha.value -= 0.05;
            } else {
                if (uAlpha.value < 1) uAlpha.value += 0.05;
            }
        }
    });

    useEffect(() => {
        let timer: ReturnType<typeof setTimeout>;

        if (isTransparent && material.current && nowIndex < 3) {
            const { uniforms } = material.current;

            timer = setTimeout(() => {
                ["uColor1", "uColor2", "uColor3", "uColor4"].forEach((key) => {
                    uniforms[key].value =
                        gradientColors[nowIndex][key as ColorVariable];
                });
            }, 500);
        }

        return () => {
            if (timer) clearTimeout(timer);
        };
    }, [isTransparent, nowIndex]);

    return (
        <mesh
            rotation={new Euler(Math.PI / 2, Math.PI, 0)}
            position={new Vector3(500, -1000, 0)}
        >
            <planeGeometry args={[5000, 5000, 32, 32]} />
            <mixedMaterial ref={material} />
        </mesh>
    );
};

export default Gradient;
