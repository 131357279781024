import { Canvas } from "@react-three/fiber";
import { useDrag } from "@use-gesture/react";
import isMobile from "ismobilejs";
import { Suspense, useState, useEffect } from "react";
import { ResizeObserver } from "@juggle/resize-observer";

import Model from "component/Model";
import UserViewCamera from "component/UserViewCamera";
import ProcessingUi from "component/ProcessingUi";
import Gradient from "component/Gradient";
import Hint from "component/Hint";
import Header from "component/Header";
import Wording from "component/Wording";

import "./App.css";

// Fix CSS variable keys in style attributes in React and Typescript: https://chaseadams.io/posts/typescript-var-cssproperties/
// Typescript: What is the correct type for a Timeout?: https://stackoverflow.com/questions/60245787/typescript-what-is-the-correct-type-for-a-timeout

const App = () => {
    const [windowSize, setWindowSize] = useState({ width: 0, height: 0 });
    const [nowIndex, setNowIndex] = useState(0);
    const [processValue, setProcessValue] = useState({
        x: 0,
        y: 0,
    });
    const [bingo, setBingo] = useState(false);
    const [closedBtnHidden, setClosedBtnHidden] = useState(false);
    const [dragging, setDragging] = useState(false);
    const [wordVisible, setWordVisible] = useState(false);
    const [openingAni, setOpeningAni] = useState({ start: false, end: false });
    const [isReseted, setIsReseted] = useState(false);
    const [gradientIsTransparent, setGradientIsTransparent] = useState(true);

    useEffect(() => {
        console.log("v20211208 - Three.js version: r134");

        let timer: ReturnType<typeof setTimeout>;
        let endTimer: ReturnType<typeof setTimeout>;

        timer = setTimeout(() => {
            setOpeningAni({ start: true, end: false });
        }, 1000);

        timer = setTimeout(() => {
            setOpeningAni({ start: true, end: true });
        }, 3000);

        const handleResize = () => {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        };

        handleResize();

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
            if (timer) clearTimeout(timer);
            if (endTimer) clearTimeout(endTimer);
        };
    }, []);

    const bind = useDrag(
        ({ down, delta: [dx, dy], first, last }) => {
            if (first) {
                setDragging(true);
            }
            if (last) setDragging(false);

            if (down && !bingo) {
                const times = isMobile(window.navigator).any ? 0.003 : 0.001;

                const px = processValue.x - dx * times;
                const py = processValue.y - dy * times;
                let lastPy = processValue.y;

                switch (true) {
                    case nowIndex === 0 && py <= -0.35:
                        lastPy = -0.35;
                        break;
                    case nowIndex === 0 && py >= 1.65:
                        lastPy = 1.65;
                        break;
                    case nowIndex === 1 && py <= 0.71:
                        lastPy = 0.71;
                        break;
                    case nowIndex === 1 && py >= 2.72:
                        lastPy = 2.72;
                        break;
                    case nowIndex === 2 && py <= 1.65:
                        lastPy = 1.65;
                        break;
                    case nowIndex === 2 && py >= 3.62:
                        lastPy = 3.62;
                        break;
                    default:
                        lastPy = py;
                        break;
                }

                setProcessValue({
                    x: px >= 0.25 ? 0.25 : px <= -0.25 ? -0.25 : px,
                    y: lastPy,
                });
            }
        },
        {
            filterTaps: true,
        }
    );

    useEffect(() => {
        let timer: ReturnType<typeof setTimeout>;
        if (bingo) {
            timer = setTimeout(() => {
                setClosedBtnHidden(true);
                setWordVisible(true);
            }, 1200);
        }

        if (!bingo) {
            setWordVisible(false);
        }

        return () => {
            if (timer) clearTimeout(timer);
        };
    }, [bingo]);

    useEffect(() => {
        let timer: ReturnType<typeof setTimeout>;
        let colorTimer: ReturnType<typeof setTimeout>;

        if (nowIndex === 0 && bingo) {
            timer = setTimeout(() => {
                setGradientIsTransparent(false);
            }, 1200);
        } else if (nowIndex === 1 && bingo) {
            timer = setTimeout(() => {
                setGradientIsTransparent(false);
            }, 1200);
        } else if (nowIndex === 2 && bingo) {
            timer = setTimeout(() => {
                setGradientIsTransparent(false);
            }, 1200);
        } else {
            setGradientIsTransparent(true);
        }

        return () => {
            if (timer) clearTimeout(timer);
            if (colorTimer) clearTimeout(colorTimer);
        };
    }, [bingo, nowIndex]);

    const handleCancelBingoStatus = () => {
        setClosedBtnHidden(false);
        setBingo(false);
        setNowIndex(nowIndex + 1);
        if (nowIndex <= 1) {
            setTimeout(() => {
                setProcessValue({ ...processValue, y: processValue.y + 0.785 });
            }, 1000);
        }

        if (nowIndex === 2) {
            console.log("reset stage");
            setIsReseted(true);
            setTimeout(() => {
                setNowIndex(0);
                setProcessValue({ x: 0, y: 0 });
            }, 1000);
        }
    };

    return (
        <div
            className="app"
            style={{
                height: `${windowSize.height}px`,
                transition: "background .5s 1s",
                pointerEvents: openingAni.end && !isReseted ? "auto" : "none",
            }}
            {...bind()}
        >
            {closedBtnHidden && (
                <div
                    className="cancel-bingo-camera"
                    onClick={handleCancelBingoStatus}
                />
            )}
            <Hint
                visible={openingAni.start}
                status={{
                    dragging,
                    bingo,
                    nowIndex,
                }}
            />
            <Header visible={openingAni.start} />
            <Wording visible={wordVisible} processValue={processValue.y} />
            <ProcessingUi
                nowIndex={nowIndex}
                bingo={bingo}
                visible={openingAni.start}
            />
            <Canvas
                gl={{
                    logarithmicDepthBuffer: true,
                    // antialias: true,
                    powerPreference: "high-performance",
                    alpha: true,
                }}
                onCreated={({ gl }) => {
                    gl.context.getExtension("OES_standard_derivatives");
                }}
                resize={{ polyfill: ResizeObserver }}
            >
                <UserViewCamera
                    nowIndex={nowIndex}
                    bingo={{
                        status: bingo,
                        changeStatus: setBingo,
                    }}
                    reset={{
                        status: isReseted,
                        changeStatus: setIsReseted,
                    }}
                />

                <Suspense fallback={null}>
                    <Model
                        nowIndex={nowIndex}
                        processValue={processValue}
                        bingo={{
                            status: bingo,
                            changeStatus: setBingo,
                        }}
                        width={windowSize.width}
                        openingAni={{
                            ...openingAni,
                            changeStatus: setOpeningAni,
                        }}
                    />
                    <Gradient
                        isTransparent={gradientIsTransparent}
                        nowIndex={nowIndex}
                    />
                </Suspense>
            </Canvas>
        </div>
    );
};

export default App;
