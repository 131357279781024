import { GLTF } from "three-stdlib";
import {
    TextureLoader,
    Euler,
    Mesh,
    Texture,
    Vector3,
    Vector2,
    DoubleSide,
    LinearFilter,
    Object3D,
    Color,
    MeshStandardMaterial,
    NearestFilter,
    NearestMipMapLinearFilter,
    NearestMipMapNearestFilter,
    LinearMipMapNearestFilter,
    LinearMipmapLinearFilter,
} from "three";
import React, {
    MutableRefObject,
    useLayoutEffect,
    useMemo,
    useRef,
    useEffect,
    useState,
    useCallback,
} from "react";
import {
    MeshProps,
    useLoader,
    useFrame,
    useThree,
    InstancedMeshProps,
    PerspectiveCameraProps,
} from "@react-three/fiber";
import { PerspectiveCamera, useGLTF } from "@react-three/drei";

import nodeNames from "./nodeNames";
import cameraPosition from "utils/cameraPosition";
import Light from "component/Light";
import computeScaledDimensions from "utils/computeScaledDimensions";

import url from "utils/path";
import { Easing, Tween, update } from "@tweenjs/tween.js";
import initialModelPosition from "./initialModelPosition";

import { cubeAmount, rotateSetting } from "./params";
import Props from "./types";
import "./ProjectedMaterial";

// How to import shader:
// https://codesandbox.io/s/react-three-fiber-custom-geometry-with-fragment-shader-material-vxswf?file=/src/index.js

// Why GLTF models and ambient Light not working:
// https://discourse.threejs.org/t/ambient-light-and-gltf-models-not-working-results-in-black-model/7428/5

type GLTFResult = GLTF & {
    nodes: any;
    marterials: {
        default: MeshStandardMaterial;
    };
};

const Model: React.FC<MeshProps & Props> = ({
    nowIndex,
    openingAni,
    processValue,
    bingo,
    width,
}) => {
    const { gl } = useThree();

    const camera1 = useRef<PerspectiveCameraProps>();
    const camera2 = useRef<PerspectiveCameraProps>();
    const camera3 = useRef<PerspectiveCameraProps>();
    const groupRef = useRef<any>();
    const shaderMaterial = useRef<any[]>([]);
    const meshMaterial = useRef<any[]>([]);
    const cubes = useRef<InstancedMeshProps>();
    const modelRotateCoords = useRef(rotateSetting);

    const [stopMoving, setStopMoving] = useState(false);
    const [backToSence, setBackToSence] = useState([false, false]);
    const [dummy] = useState(() => new Object3D());
    const [textureShow, setTextureShow] = useState({
        visible: true,
        isTrigger: false,
    });

    const models = useGLTF(`${url}/tdri_final.gltf`) as GLTFResult;
    const projectMap1 = useLoader(TextureLoader, `${url}/texture_answer1.png`);
    const projectMap2 = useLoader(TextureLoader, `${url}/texture_answer2.png`);
    const projectMap3 = useLoader(TextureLoader, `${url}/texture_answer3.png`);
    const projectPadMap1 = useLoader(TextureLoader, `${url}/texture_pad_1.png`);
    const projectPadMap2 = useLoader(TextureLoader, `${url}/texture_pad_2.png`);
    const projectPadMap3 = useLoader(TextureLoader, `${url}/texture_pad_3.png`);
    const projectMobileMap1 = useLoader(
        TextureLoader,
        `${url}/texture_mobile_1.png`
    );
    const projectMobileMap2 = useLoader(
        TextureLoader,
        `${url}/texture_mobile_2.png`
    );
    const projectMobileMap3 = useLoader(
        TextureLoader,
        `${url}/texture_mobile_3.png`
    );

    const project = useCallback((mesh: Mesh, index: number) => {
        meshMaterial.current[index].updateMatrixWorld(true);

        meshMaterial.current[
            index
        ].material.uniforms.savedModelMatrix.value.copy(mesh.matrixWorld);
    }, []);

    const updateCameraStatus = useCallback((ref: MutableRefObject<any>) => {
        if (ref.current) {
            ref.current.lookAt(0, 0, 0);
            ref.current.updateProjectionMatrix();
            ref.current.updateMatrixWorld();
            ref.current.updateWorldMatrix();
        }
    }, []);

    const updateShaderUniform = useCallback(
        (index: number, cameraRef: MutableRefObject<any>, order: number) => {
            const updateTextureScale = (
                texture: Texture,
                cameraRef: MutableRefObject<any>
            ) => {
                if (camera2.current) {
                    const [widthScaled, heightScaled] = computeScaledDimensions(
                        texture,
                        cameraRef.current,
                        1,
                        false
                    );
                    return [widthScaled, heightScaled];
                }

                return [1, 1];
            };

            shaderMaterial.current[index][
                `viewMatrixCamera${order === 1 ? "" : order}`
            ] = cameraRef.current.matrixWorldInverse.clone();
            shaderMaterial.current[index][
                `projectionMatrixCamera${order === 1 ? "" : order}`
            ] = cameraRef.current.projectionMatrix.clone();
            shaderMaterial.current[index][
                `modelMatrixCamera${order === 1 ? "" : order}`
            ] = cameraRef.current.matrixWorld.clone();
            shaderMaterial.current[index][
                `projectPosition${order === 1 ? "" : order}`
            ] = cameraRef.current.position.clone();

            const scaleRatio = updateTextureScale(projectMap2, camera2);
            shaderMaterial.current[index].scale = new Vector2(
                scaleRatio[0],
                scaleRatio[1]
            );
        },
        [projectMap2]
    );

    const modelMoving = useCallback(
        (
            from: { x: number; z: number },
            to: { [x: string]: any },
            second: number | undefined
        ) => {
            const coords = from;
            const tween = new Tween(coords);
            return tween
                .to(to, second)
                .onUpdate(() => {
                    if (groupRef.current) {
                        groupRef.current.rotation.x = coords.x;
                        groupRef.current.rotation.z = coords.z;
                    }
                })
                .onComplete(() => {
                    if (bingo.status) {
                        setStopMoving(true);
                    }
                })
                .start();
        },
        [bingo.status]
    );

    const cameraParam = useMemo(
        () => ({
            aspect: 1,
            far: 100000000,
            filmGauge: 35,
            filmOffset: 0,
            focus: 10,
            fov: 24.814,
            near: 0.001,
            scale: new Vector3(1, 1, 1),
            up: new Vector3(0, 1, 0),
            zoom: 1,
        }),
        []
    );

    const particles = useMemo(() => {
        const temp = [];
        for (let i = 0; i < cubeAmount; i++) {
            const t = Math.random() * 100;
            const factor = 20 + Math.random() * 100;
            const speed = 0.01 + Math.random() / 100;
            const xFactor = -2000 + Math.random() * 4000;
            const yFactor = -2200 + Math.random() * 5000;
            const zFactor = -1700 + Math.random() * 3000;
            temp.push({
                t,
                factor,
                speed,
                xFactor,
                yFactor,
                zFactor,
                mx: 0,
                my: 0,
            });
        }
        return temp;
    }, []);

    useLayoutEffect(() => {
        meshMaterial.current.forEach((mesh, index) => project(mesh, index));
    }, [project, projectMap1]);

    useLayoutEffect(() => {
        // texture sharpness: https://discourse.threejs.org/t/how-can-i-increase-texture-sharpness/204/4

        const maps = [
            projectMap1,
            projectMap2,
            projectMap3,
            projectPadMap1,
            projectPadMap2,
            projectPadMap3,
            projectMobileMap1,
            projectMobileMap2,
            projectMobileMap3,
        ];

        maps.forEach((map) => {
            map.anisotropy = gl.capabilities.getMaxAnisotropy();
            map.minFilter = LinearMipmapLinearFilter;
        });
    }, [
        gl.capabilities,
        projectMap1,
        projectMap2,
        projectMap3,
        projectMobileMap1,
        projectMobileMap2,
        projectMobileMap3,
        projectPadMap1,
        projectPadMap2,
        projectPadMap3,
    ]);

    useLayoutEffect(() => {
        let timer: ReturnType<typeof setTimeout>;

        if (
            meshMaterial.current.length === nodeNames.length &&
            openingAni.start
        ) {
            meshMaterial.current.forEach((mesh, index) => {
                const coords = initialModelPosition[index];
                const meshPosition = {
                    x: models.nodes[nodeNames[index]].position.x,
                    y: models.nodes[nodeNames[index]].position.y,
                    z: models.nodes[nodeNames[index]].position.z,
                };
                const tween = new Tween(coords);
                tween
                    .to(meshPosition, 2000)
                    .easing(Easing.Exponential.InOut)
                    .onUpdate(() => {
                        mesh.position.x = coords.x;
                        mesh.position.y = coords.y;
                        mesh.position.z = coords.z;
                    })
                    .start();
            });
        }

        return () => {
            if (timer) clearTimeout(timer);
        };
    }, [models.nodes, openingAni]);

    useLayoutEffect(() => {
        if (shaderMaterial.current.length === nodeNames.length) {
            shaderMaterial.current.forEach((shader, index) => {
                const { uniforms } = shader;

                uniforms.textture.value =
                    width >= 1024
                        ? projectMap1
                        : width < 768
                        ? projectMobileMap1
                        : projectPadMap1;

                uniforms.textture2.value =
                    width >= 1024
                        ? projectMap2
                        : width < 768
                        ? projectMobileMap2
                        : projectPadMap2;

                uniforms.textture3.value =
                    width >= 1024
                        ? projectMap3
                        : width < 768
                        ? projectMobileMap3
                        : projectPadMap3;

                if (index === 19) shader.side = DoubleSide;
            });
        }
    }, [
        projectMap1,
        projectMap2,
        projectMap3,
        projectMobileMap1,
        projectMobileMap2,
        projectMobileMap3,
        projectPadMap1,
        projectPadMap2,
        projectPadMap3,
        width,
    ]);

    useEffect(() => {
        if (bingo.status && !textureShow.isTrigger) {
            setTimeout(() => {
                setTextureShow({ visible: false, isTrigger: true });
            }, 800);
        }
        if (!bingo.status) {
            setTextureShow({ visible: true, isTrigger: false });
        }
    }, [bingo, textureShow.isTrigger]);

    useEffect(() => {
        if (nowIndex === 0) {
            // because tween.js update rotateSetting, so deep copy to prevent
            const newObject = JSON.parse(JSON.stringify(rotateSetting));
            modelRotateCoords.current = newObject;
        }
    }, [nowIndex]);

    useFrame(({ mouse, viewport }) => {
        if (
            camera1.current &&
            camera2.current &&
            camera3.current &&
            shaderMaterial.current.length === nodeNames.length &&
            meshMaterial.current.length === nodeNames.length
        ) {
            updateCameraStatus(camera1);
            updateCameraStatus(camera2);
            updateCameraStatus(camera3);

            shaderMaterial.current.forEach((shader, index) => {
                updateShaderUniform(index, camera1, 1);
                updateShaderUniform(index, camera2, 2);
                updateShaderUniform(index, camera3, 3);
            });
        }

        if (groupRef.current) {
            const defaultAngle = (-Math.PI / 180) * 60;
            const rotateZ = processValue.y * (Math.PI / 2);
            const isCorrectX = processValue.x > -0.05 && processValue.x < 0.05;

            // console.log(rotateZ, processValue.x)

            const isStage1 =
                nowIndex === 0 &&
                rotateZ > (Math.PI / 180) * 57 &&
                rotateZ < (Math.PI / 180) * 67;

            const isStage2 =
                nowIndex === 1 &&
                rotateZ > (Math.PI / 180) * 140 &&
                rotateZ < (Math.PI / 180) * 160;

            const isStage3 =
                nowIndex === 2 &&
                rotateZ > (Math.PI / 180) * 232 &&
                rotateZ < (Math.PI / 180) * 252;

            const rotateAngle = {
                x: processValue.x,
                z: defaultAngle + processValue.y * (Math.PI / 2),
            };

            if (isStage1 && !bingo.status && isCorrectX) {
                bingo.changeStatus(true);
                modelMoving(rotateAngle, modelRotateCoords.current[1], 500);
                setBackToSence([true, false]);
            } else if (isStage2 && !bingo.status && isCorrectX) {
                bingo.changeStatus(true);
                modelMoving(rotateAngle, modelRotateCoords.current[2], 500);
                setBackToSence([true, false]);
            } else if (isStage3 && !bingo.status && isCorrectX) {
                bingo.changeStatus(true);
                modelMoving(rotateAngle, modelRotateCoords.current[3], 500);
                setBackToSence([true, false]);
            } else if (!bingo.status) {
                const animation = modelMoving(
                    modelRotateCoords.current[nowIndex],
                    rotateAngle,
                    100
                );

                animation.onComplete(() => animation.stop()).start();
            }

            update();
        }

        if (shaderMaterial.current.length === nodeNames.length) {
            shaderMaterial.current.forEach((shader) => {
                const { uniforms } = shader;

                uniforms.nowIndex.value = nowIndex;
                uniforms.bingo.value = bingo.status;
                uniforms.processValue.value = processValue.y;

                if (textureShow.visible) {
                    if (uniforms.alpha.value > 0) uniforms.alpha.value -= 0.05;
                } else {
                    if (uniforms.alpha.value < 1) uniforms.alpha.value += 0.05;
                }
            });
        }

        particles.forEach((particle, i) => {
            let { t, factor, speed, xFactor, yFactor, zFactor } = particle;
            t = particle.t += speed / 2;
            const a = Math.cos(t) + Math.sin(t * 1) / 10;
            const b = Math.sin(t) + Math.cos(t * 2) / 10;

            particle.mx += (mouse.x * viewport.width - particle.mx) * 0.02;
            particle.my += (mouse.y * viewport.height - particle.my) * 0.02;

            dummy.position.set(
                (particle.mx / 10) * a +
                    xFactor +
                    Math.cos((t / 10) * factor) +
                    (Math.sin(t * 1) * factor) / 2,
                (particle.my / 10) * b +
                    yFactor +
                    Math.sin((t / 10) * factor) +
                    (Math.cos(t * 2) * factor) / 2,
                (particle.my / 10) * b +
                    zFactor +
                    Math.cos((t / 10) * factor) +
                    (Math.sin(t * 3) * factor) / 2
            );
            dummy.updateMatrix();
            if (cubes.current && cubes.current.setMatrixAt)
                cubes.current.setMatrixAt(i, dummy.matrix);
        });

        if (cubes.current && cubes.current.instanceMatrix)
            cubes.current.instanceMatrix.needsUpdate = true;
    });

    return (
        <>
            <group rotation={[0, 0, 0]}>
                <PerspectiveCamera
                    {...cameraParam}
                    position={
                        new Vector3(
                            cameraPosition[1].position[0],
                            cameraPosition[1].position[1],
                            cameraPosition[1].position[2]
                        )
                    }
                    rotation={
                        new Euler(
                            cameraPosition[1].rotation[0],
                            cameraPosition[1].rotation[1],
                            cameraPosition[1].rotation[2]
                        )
                    }
                    ref={camera1}
                />

                <PerspectiveCamera
                    {...cameraParam}
                    position={
                        width < 768
                            ? new Vector3(
                                  cameraPosition[4].position[0],
                                  cameraPosition[4].position[1],
                                  cameraPosition[4].position[2]
                              )
                            : new Vector3(
                                  cameraPosition[2].position[0],
                                  cameraPosition[2].position[1],
                                  cameraPosition[2].position[2]
                              )
                    }
                    rotation={
                        new Euler(
                            cameraPosition[2].rotation[0],
                            cameraPosition[2].rotation[1],
                            cameraPosition[2].rotation[2]
                        )
                    }
                    ref={camera2}
                />

                <PerspectiveCamera
                    {...cameraParam}
                    position={
                        width < 768
                            ? new Vector3(
                                  cameraPosition[5].position[0],
                                  cameraPosition[5].position[1],
                                  cameraPosition[5].position[2]
                              )
                            : new Vector3(
                                  cameraPosition[3].position[0],
                                  cameraPosition[3].position[1],
                                  cameraPosition[3].position[2]
                              )
                    }
                    rotation={
                        new Euler(
                            cameraPosition[3].rotation[0],
                            cameraPosition[3].rotation[1],
                            cameraPosition[3].rotation[2]
                        )
                    }
                    ref={camera3}
                />

                <group ref={groupRef}>
                    <Light nowIndex={nowIndex} bingo={bingo.status} />

                    <instancedMesh
                        ref={cubes}
                        args={[undefined, undefined, cubeAmount]}
                        castShadow
                        receiveShadow
                    >
                        <boxBufferGeometry
                            attach="geometry"
                            args={[40, 40, 40]}
                        />
                        <meshLambertMaterial
                            aoMapIntensity={1}
                            color={new Color(0xffffff)}
                            // side={DoubleSide}
                        />
                    </instancedMesh>

                    {nodeNames.map((nodeName, i) => (
                        <mesh
                            geometry={models.nodes[nodeName].geometry}
                            position={models.nodes[nodeName].position}
                            rotation={models.nodes[nodeName].rotation}
                            key={nodeName}
                            ref={(el) => {
                                meshMaterial.current[i] = el;
                            }}
                        >
                            <projectedMaterial
                                ref={(el: any) => {
                                    shaderMaterial.current[i] = el;
                                }}
                                attach="material"
                            />
                        </mesh>
                    ))}
                </group>
            </group>
        </>
    );
};

export default Model;
