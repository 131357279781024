const vertexShader = `
    #define STANDARD
    varying vec3 vViewPosition;
    #ifdef USE_TRANSMISSION
        varying vec3 vWorldPosition;
    #endif
    #include <common>
    #include <uv_pars_vertex>
    #include <uv2_pars_vertex>
    #include <displacementmap_pars_vertex>
    #include <color_pars_vertex>
    #include <fog_pars_vertex>
    #include <normal_pars_vertex>
    #include <morphtarget_pars_vertex>
    #include <skinning_pars_vertex>
    #include <shadowmap_pars_vertex>
    #include <logdepthbuf_pars_vertex>
    #include <clipping_planes_pars_vertex>

    uniform mat4 savedModelMatrix;
    uniform mat4 viewMatrixCamera;
    uniform mat4 projectionMatrixCamera;
    uniform mat4 modelMatrixCamera;

    uniform mat4 viewMatrixCamera2;
    uniform mat4 projectionMatrixCamera2;
    uniform mat4 modelMatrixCamera2;

    uniform mat4 viewMatrixCamera3;
    uniform mat4 projectionMatrixCamera3;
    uniform mat4 modelMatrixCamera3;

    varying vec4 vWorldPosition2;
    varying vec3 vNormal2;
    varying vec4 vTexCoords;
    varying vec4 vTexCoords2;
    varying vec4 vTexCoords3;

    varying float distToCamera;

    void main() {
        #include <uv_vertex>
        #include <uv2_vertex>
        #include <color_vertex>
        #include <beginnormal_vertex>
        #include <morphnormal_vertex>
        #include <skinbase_vertex>
        #include <skinnormal_vertex>
        #include <defaultnormal_vertex>
        #include <normal_vertex>
        #include <begin_vertex>
        #include <morphtarget_vertex>
        #include <skinning_vertex>
        #include <displacementmap_vertex>
        #include <project_vertex>
        #include <logdepthbuf_vertex>
        #include <clipping_planes_vertex>
        vViewPosition = - mvPosition.xyz;
        #include <worldpos_vertex>
        #include <shadowmap_vertex>
        #include <fog_vertex>

        vNormal2 = mat3(savedModelMatrix) * normal;
        vWorldPosition2 = savedModelMatrix * vec4(position, 1.0);
        vTexCoords = projectionMatrixCamera * viewMatrixCamera * vWorldPosition2;
        vTexCoords2 = projectionMatrixCamera2 * viewMatrixCamera2 * vWorldPosition2;
        vTexCoords3 = projectionMatrixCamera3 * viewMatrixCamera3 * vWorldPosition2;

        vec3 cs_position = mat3(savedModelMatrix) * position;
        distToCamera = length(cs_position.xyz);

        #ifdef USE_TRANSMISSION
            vWorldPosition = worldPosition.xyz;
        #endif
    }
`;

export default vertexShader;
